import Cookies from 'js-cookie'

export const write = (key, value) => {
  try {
    sessionStorage.setItem(key, value || '')
  } catch (e) {
    // Session Storage Failed: Fallback to Cookies
    Cookies.set(key, value || '')
  }
}

export const read = (key, defaultValue) => {
  let value = undefined
  try {
    value = sessionStorage.getItem(key)
  } catch (e) {
    // Session Storage Failed: Fallback to Cookies
    value = Cookies.get(key)
  }
  if (value && value.length > 0) {
    return value
  }
  return defaultValue
}

export const destroy = (key) => {
  try {
    window.sessionStorage.removeItem(key)
  } catch (e) {
    Cookies.remove(key)
  }
}

export const writeIfNotExists = (key, value) => {
  if (!read(key)) {
    write(key, value)
  }
}

export const writeUrlIfParamsPresent = (key) => {
  const params = new URLSearchParams(window.location.search)
  const hasParams = (params.has('o') && (params.get('o') || '').trim().length > 0) ||
    (params.has('j') && (params.get('j') || '').trim().length > 0) ||
    (params.has('jc') && (params.get('jc') || '').trim().length > 0)
  if (hasParams) {
    write(key, window.location.href)
  }
}
