import { read, write, destroy } from 'app/lib/storage'

// Automatically Refresh the page if a script fails to load, which should resolve
// the issue, but its also risky...as it could go into a refresh loop. The right 
// way to handle this is probably with service workers, but its much more complex.
// const retry = (fn) => new Promise((resolve, reject) => fn().then(resolve).catch(() => window.location.reload()))
export const retry = (fn) => {
  return new Promise((resolve, reject) => {
    fn().then((...args) => {
      destroy('retried')
      resolve(...args)
    }).catch((e) => {
      if (read('retried')) {
        reject(e)
      } else {
        write('retried', '1')
        window.location.reload()
      }
    })
  })
}
