const Fallback = () => (
  <div id="jsPreloader" className="page-preloader">
    <div className="page-preloader-content-centered">
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  </div>
)

export default Fallback
