import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ApolloProvider } from '@apollo/client'
import {
  Router,
  Switch,
  Route,
} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import client from './apollo'
import { retry } from 'shared/router'
import Fallback from 'app/components/page/Fallback'

Sentry.init({
  dsn: "https://006e8645eca74558a83ab7c1afbe671d@o427415.ingest.sentry.io/5371428",
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // debug: true,
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //   }
  //   return event;
  // }
});

const history = createBrowserHistory()
history.listen((location) => {
  const page_path = location.pathname + location.search
  if (window.gtag) {
    gtag('config', 'UA-174013500-1', { page_path });
  }
})

const HomePage = React.lazy(() => retry(() => import('app/pages/HomePage')))
const ApplyPage = React.lazy(() => retry(() => import('app/pages/ApplyPage')))
const PrivacyPolicyPage = React.lazy(() => retry(() => import('app/pages/PrivacyPolicyPage')))
const TermsOfServicePage = React.lazy(() => retry(() => import('app/pages/TermsOfServicePage')))
const CheckHumanPage = React.lazy(() => retry(() => import('app/pages/check/HumanPage')))
const CheckWhoPage = React.lazy(() => retry(() => import('app/pages/check/WhoPage')))
const CheckResumePage = React.lazy(() => retry(() => import('app/pages/check/ResumePage')))
const CheckRemotePage = React.lazy(() => retry(() => import('app/pages/check/RemotePage')))
const CheckDevicePage = React.lazy(() => retry(() => import('app/pages/check/DevicePage')))
const CheckBrowserPage = React.lazy(() => retry(() => import('app/pages/check/BrowserPage')))
const CheckNetworkPage = React.lazy(() => retry(() => import('app/pages/check/NetworkPage')))
const CheckSystemPage = React.lazy(() => retry(() => import('app/pages/check/SystemPage')))
// const CheckAudioPage = React.lazy(() => retry(() => import('app/pages/check/AudioPage')))
const CheckMicrophonePage = React.lazy(() => retry(() => import('app/pages/check/MicrophonePage')))
const CheckMediaPage = React.lazy(() => retry(() => import('app/pages/check/MediaPage')))
const CheckScriptPage = React.lazy(() => retry(() => import('app/pages/check/ScriptPage')))
const CheckWhyPage = React.lazy(() => retry(() => import('app/pages/check/WhyPage')))
const CheckUnitPricePage = React.lazy(() => retry(() => import('app/pages/check/UnitPricePage')))
const CheckCopyPastePage = React.lazy(() => retry(() => import('app/pages/check/CopyPastePage')))
const CheckListeningPage = React.lazy(() => retry(() => import('app/pages/check/ListeningPage')))
const CheckEducationLevelPage = React.lazy(() => retry(() => import('app/pages/check/EducationLevelPage')))
const CheckWorkAuthorizationPage = React.lazy(() => retry(() => import('app/pages/check/WorkAuthorizationPage')))
const CheckCompletePage = React.lazy(() => retry(() => import('app/pages/check/CompletePage')))
const AssessmentShowPage = React.lazy(() => retry(() => import('app/pages/assessments/ShowPage')))
const NotFoundPage = React.lazy(() => retry(() => import('app/pages/NotFoundPage')))

const AssessmentHumanPage = React.lazy(() => retry(() => import('app/pages/assessment/HumanPage')))
const AssessmentCandidatePage = React.lazy(() => retry(() => import('app/pages/assessment/CandidatePage')))
const AssessmentResumePage = React.lazy(() => retry(() => import('app/pages/assessment/ResumePage')))
const AssessmentQuestionPage = React.lazy(() => retry(() => import('app/pages/assessment/QuestionPage')))
const AssessmentCompletePage = React.lazy(() => retry(() => import('app/pages/assessment/CompletePage')))

const App = () => (
  <Sentry.ErrorBoundary fallback={"An error has occurred"} showDialog>
    <ApolloProvider client={client}>
      <Router history={history}>
        <Suspense fallback={<Fallback/>}>
          <Switch>
            <Route path="/assessment/human" component={AssessmentHumanPage}/>
            <Route path="/assessment/candidate" component={AssessmentCandidatePage}/>
            <Route path="/assessment/resume" component={AssessmentResumePage}/>
            <Route path="/assessment/question/:position" component={AssessmentQuestionPage}/>
            <Route path="/assessment/complete" component={AssessmentCompletePage}/>

            <Route path="/check/human" component={CheckHumanPage}/>
            <Route path="/check/who" component={CheckWhoPage}/>
            <Route path="/check/resume*" component={CheckResumePage}/>
            <Route path="/check/remote*" component={CheckRemotePage}/>
            <Route path="/check/device*" component={CheckDevicePage}/>
            <Route path="/check/browser*" component={CheckBrowserPage}/>
            <Route path="/check/network*" component={CheckNetworkPage}/>
            <Route path="/check/system*" component={CheckSystemPage}/>
            {/* <Route path="/check/audio*" component={CheckAudioPage}/> */}
            <Route path="/check/microphone*" component={CheckMicrophonePage}/>
            <Route path="/check/media*" component={CheckMediaPage}/>
            <Route path="/check/script*" component={CheckScriptPage}/>
            <Route path="/check/why*" component={CheckWhyPage}/>
            <Route path="/check/unit_price*" component={CheckUnitPricePage}/>
            <Route path="/check/copy_paste*" component={CheckCopyPastePage}/>
            <Route path="/check/listening*" component={CheckListeningPage}/>
            <Route path="/check/education_level*" component={CheckEducationLevelPage}/>
            <Route path="/check/work_authorization*" component={CheckWorkAuthorizationPage}/>
            <Route path="/check/complete" component={CheckCompletePage}/>
            <Route path="/assessments/:id" component={AssessmentShowPage}/>
            <Route path="/apply" component={ApplyPage}/>
            <Route path="/privacy" component={PrivacyPolicyPage}/>
            <Route path="/terms" component={TermsOfServicePage}/>
            <Route path="/" exact component={HomePage} />
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
      </Router>
    </ApolloProvider>
  </Sentry.ErrorBoundary>
)

export default App
