import { ApolloClient, InMemoryCache } from '@apollo/client'

const cache = new InMemoryCache()
const csrfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')
const options = {
  uri: '/graphql',
  credentials: 'same-origin',
  headers: {
    'X-CSRF-Token': csrfToken
  },
  cache
}

const client = new ApolloClient(options)

export default client
