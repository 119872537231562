import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginReact from '@bugsnag/plugin-react'
import App from '../app'
import { writeIfNotExists, writeUrlIfParamsPresent } from '../app/lib/storage'

import '../app/styles/index.scss'

console.log('~~~ PerfectJob ~~~')
Sentry.init({
  dsn: "https://25e45f265886436bbcf3843793614f26@o253822.ingest.sentry.io/5450694",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})


// Bugsnag.start({
//   appType: 'client',
//   appVersion: process.env.SOURCE_VERSION,
//   apiKey: 'e64ac2cbbcb52c019cb8cfcf9f772585',
//   plugins: [new BugsnagPluginReact()],
//   // releaseStage: process.env.NODE_ENV
//   enabledReleaseStages: [ 'production', 'staging' ],
// })
// const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// TRACKING - RUNS ONCE ON PAGE LOAD
writeIfNotExists('lurl', window.location.href)
writeIfNotExists('rurl', window.document.referrer)
writeUrlIfParamsPresent('aurl')

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    // <ErrorBoundary><App /></ErrorBoundary>,
    <App />,
    document.body.appendChild(document.createElement('div')),
  )
})
